import styled from 'styled-components';
import { rem } from 'polished';

import { ItemContainer } from '../../Theme/Layout';

export const FriendRequestContainer = styled(ItemContainer)`
	display: flex;
	button {
		margin-left: ${rem(16)};
	}
	@media screen and (max-width: 500px) {
		button {
			margin-left: 0;
		}
		display: grid;
		grid-template-columns: 30px 1fr auto;
		gap: ${rem(4)};
		.mantine-Avatar-root {
			width: 25px;
			height: 25px;
			min-width: 25px;
		}
	}
`;

export const InfoContainer = styled.div`
	height: 100%;
	width: 100%;

	> h3 {
		margin: 0;
	}

	> div {
		margin: 0;
		width: 100%;
		display: flex;
	}

	p {
		margin: 0;
	}

	blockquote {
		margin-left: ${rem(16)};
		margin-top: ${rem(4)};
		color: ${props => props.theme.text.muted};
	}

	@media screen and (max-width: 500px) {
		/* margin-left: 0.5rem; */
		width: auto;
		max-width: 215px;
	}
`;

export const ActionsContainer = styled.div`
	display: flex;
	@media screen and (max-width: 500px) {
		flex-direction: column;
		gap: 4px;
	}
`;
