import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';

import Loading from '../../Theme/Loading';
import { PeachContext } from '../../PeachContext';
import { User, CurUser } from '../../api/interfaces';
import ACTIONS from '../../api/constants';
import api from '../../api';
import { LinkStyled } from './style';
import { Preview } from '../../components/Feed/Preview';
import { Page } from '../../Theme/Layout';
import { Title } from '../../Theme/Type';
import { RiseAndFadeAnimationContainer } from '../../Theme/Animations';
import { TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons';

import './style.css';

const SearchBar = (props: {
	searchTerm: string;
	setSearchTerm: (searchTerm: string) => void;
	placeholder: string;
}) => {
	return (
		<div className='search-bar'>
			<TextInput
				type='text'
				placeholder={props.placeholder}
				value={props.searchTerm}
				onChange={e => props.setSearchTerm(e.target.value)}
				rightSection={
					props.searchTerm.length > 0 ? (
						<IconX
							size={18}
							color='#666'
							onClick={() => props.setSearchTerm('')}
						/>
					) : (
						<IconSearch size={18} color='#666' />
					)
				}
			/>
		</div>
	);
};

const fuseOptions = {
	threshold: 0.3,
	keys: ['displayName', 'name'],
};

const Feed = (props: { connections: User[] }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredConnections, setFilteredConnections] = useState<User[]>([]);
	const { connections } = props;

	useEffect(() => {
		const fuse = new Fuse(connections, fuseOptions);
		if (searchTerm === '') {
			setFilteredConnections(connections);
			return;
		}
		setFilteredConnections(
			fuse.search(searchTerm).map((result: any) => result.item)
		);
	}, [searchTerm, connections]);
	return (
		<>
			<SearchBar
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				placeholder='Search Friends...'
			/>
			{filteredConnections.map(user => (
				<LinkStyled key={user.id} to={`/friend/${user.id}`}>
					<Preview
						key={user.id}
						avatarSrc={user.avatarSrc}
						displayName={user.displayName}
						name={user.name}
						id={user.id}
						message={
							user.posts && user.posts[0] ? user.posts[0].message[0] : ''
						}
						unread={user.unreadPostCount > 0}
						createdTime={
							user.posts && user.posts[0] ? user.posts[0].createdTime : null
						}
						isFavorite={user.isFavorite}
					/>
				</LinkStyled>
			))}
		</>
	);
};

export const FeedPage = () => {
	const {
		jwt,
		curUser,
		curUserData,
		setCurUserData,
		connections,
		isPeachLoading,
	} = useContext(PeachContext);
	const [isCurUserDataLoading, setIsCurUserDataLoading] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		if (!jwt || !curUser) {
			navigate('/login', { replace: true });
		}

		setIsCurUserDataLoading(true);

		api(ACTIONS.connectionStream, jwt, {}, curUser?.id, 'FeedPage').then(
			(response: { data: CurUser }) => {
				if (response.data) {
					setCurUserData(response.data);
				}
			}
		);
		setIsCurUserDataLoading(false);
		// eslint-disable-next-line
	}, [curUserData.id, curUser, jwt]);

	return (
		<>
			<Page>
				<RiseAndFadeAnimationContainer>
					<Title>All Feeds</Title>
					{isPeachLoading || isCurUserDataLoading ? (
						<Loading />
					) : (
						<Feed connections={connections} />
					)}
				</RiseAndFadeAnimationContainer>
			</Page>
		</>
	);
};
