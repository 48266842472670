import React, { useState } from 'react';
import { Alert, Button, FileButton, Space, Tooltip } from '@mantine/core';
import { PageContainer } from '../Theme/Layout';
import { MiniLoader } from '../Theme/Loading';
import { Post } from '../api/interfaces';
import { ProfilePost } from './Profile/ProfilePost';
import { DEFAULT_AVATAR_SRC } from '../constants';
import { Text } from '../Theme/Type';
import { IconInfoCircle, IconUpload } from '@tabler/icons';
import { SettingsSection } from './Settings/style';
import { Link } from 'react-router-dom';

export const ExportedPostsViewer = () => {
	const [isProcessing, setIsProcessing] = useState(false);
	const [posts, setPosts] = useState<Post[]>([]);
	const [filename, setFilename] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	return (
		<PageContainer>
			<SettingsSection>
				<h2>View exported posts</h2>
				<FileButton
					accept='.json'
					onChange={(file: File | null) => {
						setIsProcessing(true);
						setFilename(null);
						if (file) {
							setFilename(file.name);
							const reader = new FileReader();
							reader.onload = e => {
								const text = e.target?.result as string;
								const posts = JSON.parse(text);
								if (!Array.isArray(posts)) {
									setError('Invalid file format');
									setPosts([]);
									return;
								}
								const firstPost = posts[0];
								if (!firstPost) {
									setError('No posts found in file');
									setPosts([]);
									return;
								}

								if (!firstPost.id) {
									setError('Invalid post format');
									setPosts([]);
									return;
								}
								setPosts(posts);
							};
							reader.readAsText(file);
						}
						setIsProcessing(false);
					}}
				>
					{props => (
						<Tooltip
							label='Upload a JSON file containing exported posts'
							withArrow
						>
							<Button leftIcon={<IconUpload />} {...props}>
								Upload posts file for viewing
							</Button>
						</Tooltip>
					)}
				</FileButton>

				<Space h='sm' />
				<Alert icon={<IconInfoCircle />}>
					You can download your posts on the{' '}
					<Link to='/settings'>settings page</Link>.
				</Alert>

				{!isProcessing && filename ? (
					<Text>Viewing file: {filename}</Text>
				) : null}
				{isProcessing && <MiniLoader />}
				{error && <Text color='red'>{error}</Text>}
			</SettingsSection>

			{posts.map(post => (
				<ProfilePost
					{...post}
					key={post.id}
					deletePost={() => {}}
					postAuthorAvatarSrc={DEFAULT_AVATAR_SRC}
					otherFriends={[]}
					author='User'
					isFakePreview
				/>
			))}
		</PageContainer>
	);
};
