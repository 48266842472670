export const STORAGE_USER_KEY = 'user';
export const STORAGE_TOKEN_KEY = 'peachedToken';
export const STORAGE_IS_DARK_MODE = 'peachedDarkMode';
export const STORAGE_CUR_USER_DATA_KEY = 'peachedCurUserData';
export const STORAGE_IS_BETA_ENABLED = 'peachedBetaEnabled';

export const LINKIFY_OPTIONS = {
	defaultProtocol: 'http',
	target: '_blank',
};

export const DEFAULT_AVATAR_SRC =
	'https://ja7dk95knuvlhzfc.public.blob.vercel-storage.com/peachavatar.jpg';
